import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { familyTreeUpdateType, ForeverLovedDaysType, GetUserType, MemorialTab, MemorialType, PageResultType, StoryLifeEventArrayType, StoryLifeEventType, StoryMemoriesArrayType, StoryMemoriesType } from "../AppType";
import { useTranslation } from "react-i18next";
import { fetchRecords, debug, ASSET_URL, BASE_URL } from "../../utils/AppUtil";
import { AppContext } from "../../AppContext";
import { UserAccount } from "../UserAccount/ModalUserAccount";
import { ModalCreateMemorial } from "../Modals/ModalCreateMemorial";
import { AddToHome } from "../Modals/ModalAddToHome";
import { MemorialContext } from "../../MemorialContext";
import { StoryDetailed } from "./StoryDetailed";
import { useNavigate } from "react-router-dom";
import { ModalFamilyTree } from "../FamilyTree/ModalFamilyTree";
import 'animate.css';

type HomeProps = {
    activeTab: MemorialTab,
    setActiveTab: Dispatch<SetStateAction<MemorialTab>>,
    isActiveTab:  (pageId: MemorialTab) => number,
    handleNavClick: (pageId: MemorialTab) => void
}

type MemorialPictures = {
    memorialId: string,
    pictureId: string
}[]

export const Home: React.FC<HomeProps> = (props) => {

    //BUILD UPDATE
    const [buildNum, setBuildNum] = useState<number>(2);
    
    // Internationalisation
    const { t, i18n } = useTranslation();

    // Authentication data from AppContext
    const { commonData } = useContext(AppContext)!;
    
    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;
    
    const [memorials, setMemorials] = useState<React.ReactElement[]>();
    const [foreverLovedDays, setForeverLovedDays] = useState<React.ReactElement[]>();
    const [memoryStories, setMemoryStories] = useState<React.ReactElement[]>();
    const [lifeEventStories, setLifeEventStories] = useState<React.ReactElement[]>();
    const [combinedStories, setCombinedStories] = useState<React.ReactElement[]>();
    const [sortedStories, setSortedStories] = useState<React.ReactElement[]>();
    const [userPictureSrc, setUserPictureSrc] = useState<string>('images/fl-days-avatar.svg');
    const [userPictureId, setUserPictureId] = useState<string>('');
    const [userGender, setUserGender] = useState<string>('');

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [emptyState, setEmptyState] = useState<boolean>(false)
    
    const [language, setLanguage] = useState<string>("en");
    
    const gbFlag: string = "/images/flags/flag-gb.svg";
    const frFlag: string = "/images/flags/flag-fr.svg";
    const [currentFlag, setCurrentFlag] = useState(gbFlag);

    const [splashShow, setSplashShow] = useState<boolean>(false);
    const [splashAnimation, setSplashAnimation] = useState('');

    const [FLDays, setFLDays] = useState<boolean>(false);

    const [selectedStoryData, setSelectedStoryData] = useState<{
        creatorFirstName: string,
        creatorLastName: string,
        title: string,
        message: string,
        eventDate: string,
        isMemory: boolean
    }>();

    const [selectedStoryImages, setSelectedStoryImages] = useState<{
        pictureId: string,
        isVideo: boolean,
        title: string,
        message: string,
        memorialFirstName: string,
        memorialLastName: string
    }[]>();
    const [storyClicked, setStoryClicked] = useState<number>(0);

    const [showStoryModal, setShowStoryModal] = useState(false);
    const handleShowStory = () => setShowStoryModal(true);
    const handleCloseStory = () => setShowStoryModal(false);

    const createMemorialNodeDefault: familyTreeUpdateType = {
        treeNodeId: "",
        userId: "",
        memorialId: "",
        relationship: "",
        proxyFirstName: "",
        proxyLastName: "",
        proxyType: "",
        data: {
            posX: 0,
            posY: 0,
            handleTo: "",
            handleFrom: "",
            fromTreeNodeId: ""
        }
    }

    const navigate = useNavigate();

    // const changeLanguage = () => {
    //     if(language === "en") {
    //         setLanguage("fr");
    //         setCurrentFlag(frFlag)
    //         i18n.changeLanguage("fr");
    //     } else {
    //         setLanguage("en");
    //         setCurrentFlag(gbFlag)
    //         i18n.changeLanguage("en");
    //     }
    // }

    // HOMEPAGE DISPLAY ----------
    useEffect(() => {

        fetchRecords(commonData.token, 'memorial/homepage', processMemorialData)
        .catch((error) => {
            setIsLoading(false);
            debug('fetchRecords error: ' + error, 'Home - Memorials');
        });

        fetchRecords(commonData.token, 'user/loggedIn/dates?cutoffDays=1000', processForeverLovedDaysData)
        .catch((error) => {
            setIsLoading(false);
            debug('fetchRecords error: ' + error, 'Home - ForeverLovedDays');
        });
        
        fetchRecords(commonData.token, 'memory/homepage', processMemoryStories)
        .catch((error) => {
            setIsLoading(false);
            debug('fetchRecords error: ' + error, 'Home - Memories(stories)');
        });

        fetchRecords(commonData.token, 'life_event/homepage', processLifeEventStories)
        .catch((error) => {
            setIsLoading(false);
            debug('fetchRecords error: ' + error, 'Home - Life Events (stories)');
        });

        fetchRecords(commonData.token, `user/loggedIn`, processUserData)
        .catch((error) => {
            debug('fetchRecords error: ' + error, 'user');
        });

    }, [memorialData, language]);


    const processUserData = (responseJson: GetUserType) => {
        setUserGender(responseJson.gender);
        if (responseJson.profilePicture?.pictureId) {
            setUserPictureSrc(`${ASSET_URL}/public/picture/${responseJson.profilePicture.pictureId}`);
            setUserPictureId(responseJson.profilePicture.pictureId);
        }
    }

    useEffect(() => {

        // Combine the two states, defaulting to an empty array if undefined
        const newStories = [...(memoryStories || []), ...(lifeEventStories || [])];

        setCombinedStories(newStories);
    }, [memoryStories, lifeEventStories]);

    useEffect(() => {
        if (combinedStories && combinedStories.length > 0) {
            const newSortedStories = [...combinedStories].sort((a, b) => {
                const aTimestamp = new Date(a.props.id).getTime();
                const bTimestamp = new Date(b.props.id).getTime();
                return bTimestamp - aTimestamp;
            });
    
            // Only update state if the sorted array is different from the current state
            setSortedStories(prevSortedStories => {
                if (JSON.stringify(prevSortedStories) !== JSON.stringify(newSortedStories)) {
                return newSortedStories;
                }
                return prevSortedStories; // Avoid triggering a re-render if nothing has changed
            });
        }
    }, [combinedStories]); // Only re-run this effect if the stories array changes

    // Simulate click to combat mobile touch swipe bug
    useEffect(() => {
        document.getElementById("discoverCommunitiesSlide1")?.click();
    }, []);

    const processMemorialData = (responseJson: PageResultType) => {
        setIsLoading(true);
        setMemorials(buildMemorialsJSX(responseJson));
        
        setIsLoading(false);

        if (responseJson.numRecords === 0) {
            setEmptyState(true);
        }
    }

    const buildMemorialsJSX = (response: PageResultType): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 0;

        response.data.forEach((memorial: MemorialType) => {

            let profilePictureSrc = "images/fl-avatar-min.svg";
            if (memorial.memorialPictureId) {
                profilePictureSrc = `${ASSET_URL}/public/picture/${memorial.memorialPictureId}`
            }

            let backgroundImg = "/images/covor_photo_default_Small.png";
            if (memorial.coverPictureId) {
                backgroundImg = `${ASSET_URL}/public/picture/${memorial.coverPictureId}`
            }

            result.push(

                <div key={key} className="home-memorial-tile">

                    {memorial.role != "VIEWER" && 
                        <div className="home-tile-badge">
                            {memorial.role === "CREATOR" &&
                                <p>{t("Home.Memorials.roleOwner", {ns: "components"})}</p>
                            }
                            {memorial.role === "ADMIN" &&
                                <p>{t("Home.Memorials.roleAdmin", {ns: "components"})}</p>
                            }
                            {memorial.role === "CONTRIBUTOR" &&
                                <p>{t("Home.Memorials.roleEditor", {ns: "components"})}</p>
                            }
                        </div>
                    }

                    {/* style={{backgroundImage: `linear-gradient(rgba(0, 34, 47, 0) -100%, rgb(6, 45, 60) 50%), url('${backgroundImg}')`}} */}

                    <div className="home-memorial-tile-body d-flex flex-column align-items-center justify-content-between p-3 memorial-tile-profile-cover">
                        <div className="d-flex flex-column gap-2">
                            <div className="memorial-tile-profile-picture-wrapper">
                                <img src={profilePictureSrc} className="memorial-tile-profile-picture" width="84" height="84" alt="" />
                            </div>

                            <div>
                                <p>{memorial.firstName}</p>
                                <p>{memorial.lastName}</p>
                            </div>
                        </div>

                        {/* <p>{memorial.birthYear} - {memorial.deathYear}</p> */}

                        <div
                            className="btn fl-btn-white w-100"
                            onClick={() => {setMemorialData(memorial); props.handleNavClick(MemorialTab.tab1);}}>
                            View
                        </div>
                    </div>
                    
                </div>
            );
            key++;
        });

        return result;
    }

    const processForeverLovedDaysData = (responseJson: ForeverLovedDaysType[]) => {
        if (responseJson.length === 0) {
            setFLDays(true);
        } else {
            setFLDays(false);
        }

        setIsLoading(true);
        setForeverLovedDays(buildForeverLovedDaysJSX(responseJson));
        setIsLoading(false);
    }

    const buildForeverLovedDaysJSX = (response: ForeverLovedDaysType[]): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 0;

        const today = (new Date()).getTime();
        const msDay = 24 * 60 * 60 * 1000; // milliseconds per day
        
        let date;
        let formattedDate;

        response.forEach((day: ForeverLovedDaysType) => {

            date = (new Date(day.date)).getTime();
            formattedDate = Math.floor((date - today) / msDay);

            let dayColor = "";
            let dayText = "";

            if (day.dateType === "BIRTH") {
                dayColor = "#198754";
                dayText = "Heavenly Birthday";

            } else if (day.dateType === "DEATH") {
                dayColor = "#FD9843";
                dayText = "Day of Passing";

            } else if (day.dateType === "YAHRZEIT") {
                dayColor = "#8540F5";
                dayText = "Yahrzeit";

            } else if (day.dateType === "FATHERS_DAY") {
                dayColor = "#D63384";
                dayText = "Father's Day";

            } else if (day.dateType === "MOTHERS_DAY") {
                dayColor = "#D63384";
                dayText = "Mother's Day";
            }

            let pictureSrc = "images/fl-days-avatar.svg";
            if (day.memorial.memorialPictureId) {
                pictureSrc = `${ASSET_URL}/public/picture/${day.memorial.memorialPictureId}`
            }

            result.push(
                <div key={key}
                    className="fl-day"
                    style={{background: `linear-gradient(to bottom right, ${dayColor}1A 50%, #052734 100%) 100% no-repeat`}}
                    onClick={() => {setMemorialData(day.memorial); props.handleNavClick(MemorialTab.tab3);}}
                >
                    <div className="d-flex gap-3">
                        <img src={pictureSrc} width={64} height={64} />

                        <div className="d-flex flex-column justify-content-center gap-1">
                            <p className="fl-day-name">{day.userFirstName}<br/>{day.userLastName}'s</p>
                            <p className="fl-day-relation">{day.relationship}</p>
                        </div>
                    </div>

                    <div className="w-100">
                        <div className="fl-day-type" style={{backgroundColor: dayColor + '33'}}>
                            <div className="d-flex align-items-center gap-2">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginTop: "2px"}}>
                                    <g clipPath="url(#clip0_10976_74125)">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.00008 0.985539C9.32858 -2.43596 17.6506 3.55129 6.00008 11.25C-5.65042 3.55204 2.67158 -2.43596 6.00008 0.985539Z" fill={dayColor}/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_10976_74125">
                                            <rect width="12" height="12" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>

                                <div className="d-flex flex-column">
                                    <p>{dayText}</p>

                                    {formattedDate === 0 ?
                                        <p className="fl-day-date"><span>Today</span></p>
                                        :
                                        <p className="fl-day-date">in <span>{formattedDate} days</span></p>
                                    }
                                </div>
                            </div>

                            {day.dateType === "YAHRZEIT" &&
                                <>
                                    <div className="dropdown-center pt-2" style={{position: 'static'}}>

                                        {/* data-bs-display="static" */}
                                        <img className="dropdown-toggle" src="/images/fl-icon-info.svg" data-bs-toggle="dropdown" aria-expanded="false" />

                                        <div className="dropdown-menu yahrzeit-dropdown">
                                            <img src="/images/fl-dropdown-triangle.svg" />
                                            <p className="mb-1"><b>What is a Yahrzeit?</b></p>
                                            <p>A Jewish tradition marking the anniversary of a loved one's death, observed with prayers, memorial candles, and acts of remembrance.</p>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            );
            key++;
        });

        return result;
    }

    const processMemoryStories = (responseJson: StoryMemoriesArrayType[]) => {
        setIsLoading(true);
        if (responseJson) {
            setMemoryStories(buildMemoryStoriesJSX(responseJson));
        }
        setIsLoading(false);
    }

    const buildMemoryStoriesJSX = (response: StoryMemoriesArrayType[]): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 0;        

        response.forEach((story: StoryMemoriesArrayType) => {
            let pictureSrc = "/images/story-placeholder-img.png"
            const lastStory: number = story.length - 1;

            if (story[lastStory]?.pictures[0]) {
                pictureSrc = `${ASSET_URL}/public/picture/${story[lastStory]?.pictures[0].pictureId}`;
            }

            const groupedStoryImages: any[] = [];

            story.forEach((memory: StoryMemoriesType) => {

                if (memory.pictures[0]) {
                    groupedStoryImages.unshift(...memory?.pictures.map(picture => ({
                        pictureId: picture.pictureId,
                        isVideo: picture.isVideo,
                        title: memory?.title,
                        message: memory?.message,
                        memorialFirstName: memory?.memorialFirstName,
                        memorialLastName: memory?.memorialLastName,
                    })));

                } else {
                    groupedStoryImages.unshift({
                        pictureId: null,
                        isVideo: false,
                        title: memory?.title,
                        message: memory?.message,
                        memorialFirstName: memory?.memorialFirstName,
                        memorialLastName: memory?.memorialLastName,
                    });
                }
            });
                
            result.unshift(
                <div key={key} className="story-tile-wrapper" onClick={() => {
                    setSelectedStoryImages(groupedStoryImages);
                    
                    setSelectedStoryData({
                        creatorFirstName: story[0].creator.firstName,
                        creatorLastName: story[0].creator.lastName,
                        title: story[0].title,
                        message: story[0].message,
                        eventDate: "",
                        isMemory: true
                    });

                    setStoryClicked(c => c + 1);
                    handleShowStory();
                }}>
                    <div className="story-tile memory">
                        <div className="story-image-wrapper p-2">
                            <div className="story-cover-image" style={{backgroundImage: `url(${pictureSrc})`}} />
                        </div>
                    </div>

                    <div className="story-title">
                        <p>{story[0]?.creator.firstName}</p>
                        <p>{story[0]?.creator.lastName}</p>
                    </div>
                </div>
            );
            key++;
        });

        return result;
    }

    const processLifeEventStories = (responseJson: StoryLifeEventArrayType[]) => {
        setIsLoading(true);
        setLifeEventStories(buildLifeEventStoriesJSX(responseJson));
        setIsLoading(false);
    }

    const buildLifeEventStoriesJSX = (response: StoryLifeEventArrayType[]): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 0;        

        response.forEach((story: StoryLifeEventArrayType) => {
            let pictureSrc = "/images/story-placeholder-img.png"
            const lastStory: number = story.length - 1;

            if (story[lastStory]?.pictureIds[0]) {
                pictureSrc = `${ASSET_URL}/public/picture/${story[lastStory]?.pictureIds[0]}`;
            }

            const groupedStoryImages: any[] = [];
            story.forEach((lifeEvent: StoryLifeEventType) => {

                if (lifeEvent.pictureIds[0]) {
                    groupedStoryImages.unshift(...lifeEvent?.pictureIds.map(picture => ({
                        pictureId: picture,
                        isVideo: false,
                        title: lifeEvent?.title,
                        message: lifeEvent?.message,
                        memorialFirstName: lifeEvent?.memorialFirstName,
                        memorialLastName: lifeEvent?.memorialLastName,
                    })));
    
                } else {
                    groupedStoryImages.unshift({
                        pictureId: null,
                        isVideo: false,
                        title: lifeEvent?.title,
                        message: lifeEvent?.message,
                        memorialFirstName: lifeEvent?.memorialFirstName,
                        memorialLastName: lifeEvent?.memorialLastName,
                    });
                }
            
            });

            result.unshift(
                <div key={key} className="story-tile-wrapper" onClick={() => {
                    setSelectedStoryImages(groupedStoryImages);

                    setSelectedStoryData({
                        creatorFirstName: story[0].creator.firstName,
                        creatorLastName: story[0].creator.lastName,
                        title: story[0].title,
                        message: story[0].message,
                        eventDate: "",
                        isMemory: false
                    });

                    setStoryClicked(c => c + 1);
                    handleShowStory();
                }}>
                    <div className="story-tile life-event">
                        <div className="story-image-wrapper p-2">
                            <div className="story-cover-image" style={{backgroundImage: `url(${pictureSrc})`}} />
                        </div>
                    </div>

                    <div className="story-title">
                        <p>{story[0]?.creator.firstName}</p>
                        <p>{story[0]?.creator.lastName}</p>
                    </div>
                </div>
            );
            key++;
        });

        return result;
    }

    const handleClickLogout = () => {
        navigate('/login');
    }

    const handleViewMemorial = () => {
        console.log('Drilled!');
        props.handleNavClick(MemorialTab.tab1);
    }

    return (
        <>
            <AddToHome />

            {splashShow &&
                <div className={`splash-loading-div ${splashAnimation}`}>
                    <img src="/images/splash-logo-new.svg" className="mb-3" alt="" />
                    <p>forever loved</p>
                    <p>Keep their stories alive</p>
                </div>
            }

            <div className="page-wrapper home">

                {/* Icons */}
                <div className="home-header">
                    <p onClick={() => window.location.reload()}>{ t("Home.title", {ns: "components"}) }</p>
                    <div className="d-flex gap-3">
                        <UserAccount />
                        <img src="/images/fl-icon-heart-1.svg" className="home-icon-top" height={40} onClick={handleClickLogout} />
                        <img src="/images/fl-icon-search.svg" className="home-icon-top" height={40} />
                    </div>
                </div>

                {/* Stories */}
                <div className="stories-wrapper pe-3 mb-3">
                    <div className="story-tile-wrapper">
                        <div className="story-tile add-memory">
                            <img src={`images/btn-add-story.svg`} className="btn-add-story" />
                            <div className="story-image-wrapper p-1">
                                <img src={userPictureSrc} width={80} height={80} style={{borderRadius: '50%'}} />
                            </div>
                        </div>

                        <div className="story-title">
                            <p>Add a</p>
                            <p>memory</p>
                        </div>
                    </div>

                    {sortedStories}
                </div>

                {FLDays ?
                    <>
                        <div className="d-flex gap-3">
                            <p className="fl-txt-home-subheading ps-3" style={{whiteSpace: "nowrap"}}>Introducing Forever Loved Days</p>
                            <div className="home-memorials-heading-line">&nbsp;</div>
                        </div>

                        <div className="p-3 mb-3">
                            <div className="card-container-top d-flex justify-content-center">
                                <img src="/images/fl-days-video.png" width="100%" />
                            </div>

                            <div className="card-container-bottom" style={{backgroundColor: '#FFFFFF0D'}}>
                                <div className="d-flex flex-column gap-3">
                                    <p className="fl-days-intro">Turn on contact uploading to celebrate more Forever Loved days</p>
                                    <div className="d-flex gap-3">
                                        <div className="fl-days-btn-1"><p>Not now</p></div>
                                        <div className="fl-days-btn-2"><p>Turn on</p></div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="d-flex gap-3">
                            <p className="fl-txt-home-subheading ps-3" style={{whiteSpace: "nowrap"}}>Reminders</p>
                            <div className="home-memorials-heading-line">&nbsp;</div>
                        </div>

                        <div className="fl-days-wrapper pe-3 mb-3">

                            {foreverLovedDays}

                            <div className="fl-day fl-day-add">
                                <div className="d-flex flex-column justify-content-center align-items-center gap-2 h-100">
                                    <img src="/images/fl-logo-small.svg" />
                                    <p className="add-connections-txt">Add connections to see more <b>Forever Loved days</b></p>
                                </div>
                                <div className="btn fl-btn-dark w-100 fw-bold" style={{padding: "12px"}}>Add connections</div>
                            </div>
                        </div>
                    </>
                }

                
                {/* Memorials heading */}
                <div className="d-flex gap-3">
                    <p className="fl-txt-home-subheading ps-3" style={{whiteSpace: "nowrap"}}>Memorials</p>
                    <div className="home-memorials-heading-line">&nbsp;</div>
                </div>

                {/* Memorials */}
                <div className="d-flex flex-column justify-content-between">
                    {!emptyState ?
                        <div className="home-memorials">
                            {memorials}

                            <div className="home-memorial-tile placeholder-memorial">
                                <div className="home-memorial-tile-body placeholder-memorial p-3 memorial-tile-profile-cover">
                                    <div className="d-flex flex-column gap-2">
                                        <div className="memorial-tile-profile-picture-wrapper placeholder-memorial">
                                            <img src="images/fl-avatar-female.svg" className="memorial-tile-profile-picture" width="84" height="84" alt="" />
                                        </div>

                                        <div>
                                            <p>Freya</p>
                                            <p>Halevi</p>
                                        </div>
                                    </div>

                                    {/* <p>{memorial.birthYear} - {memorial.deathYear}</p> */}

                                    <div className="btn fl-btn-dark w-100">Create</div>
                                </div>
                                
                            </div>

                            <ModalCreateMemorial emptyState={false} relation={""} link={""} fromFamilyTree={false} selectedNode={createMemorialNodeDefault} />
                        </div>
                        :
                        <div className="card card-no-memorials m-3">
                            <img src="/images/img-no-memorials.png" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <div className="card-text d-flex flex-column gap-2 mb-4">
                                    <p>Memorials</p>
                                    <p>Remember your loved ones with memorials that honour their enduring legacy.</p>
                                </div>
                                <ModalCreateMemorial emptyState={true} relation={""} link={""} fromFamilyTree={false} selectedNode={createMemorialNodeDefault} />
                            </div>
                        </div>
                    }
                </div>

                {/* Family trees heading */}
                <div className="d-flex gap-3">
                    <p className="fl-txt-home-subheading ps-3" style={{whiteSpace: "nowrap"}}>Family trees</p>
                    <div className="home-memorials-heading-line">&nbsp;</div>
                </div>

                {/* Family trees */}
                <div className="p-3">
                    <div className="home-family-trees-tile">
                        <div className="tree-relatives-badge">Empty</div>
                        <div className="tree-img-wrapper">
                            <img src="images/headshot-placeholder.svg" width={64} height={64} />
                        </div>
                        <div className="tree-tile-body">
                            <p className="tree-name">The {commonData.lastName} Tree</p>
                            <p className="tree-creator">by <span>You</span></p>
                            <ModalFamilyTree handleViewMemorial={handleViewMemorial} userGender={userGender} userPicture={userPictureId} />
                        </div>
                    </div>
                </div>

            </div>

            <StoryDetailed data={selectedStoryData} images={selectedStoryImages} showModal={showStoryModal} onHide={handleCloseStory} />
        </>
    );
}