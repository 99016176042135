import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "animate.css"
import { ASSET_URL, debug, deleteRecord, fetchRecords, postFormData } from "../../utils/AppUtil";
import { AppContext } from "../../AppContext";
import { MemorialContext } from "../../MemorialContext";
import { PageResultType, MemorialType, DetailedNode, familyTreeUpdateType, } from "../AppType";

type ModalProps = {
    selectedNode: familyTreeUpdateType;
    showModal: boolean;
    closeModal: () => void;
}

export const LinkMemorial: React.FC<ModalProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [selected, setSelected] = useState<boolean>(false);

    // Modal states
    const [show, setShow] = useState(false);

    const [memorials, setMemorials] = useState<React.ReactElement[]>();
    const [selectedMemorialId, setSelectedMemorialId] = useState<string | null>(null);

    const formData: familyTreeUpdateType = {
        treeNodeId: '',
        userId: '',
        memorialId: '',
        relationship: '',
        data: {
            posX: 0,
            posY: 0,
            handleTo: '',
            handleFrom: '',
            fromTreeNodeId: ''
        }
    }

    const [formState, setFormState] = useState<familyTreeUpdateType>(formData);

    useEffect(() => {
        fetchRecords(commonData.token, 'memorial/homepage', processMemorialData)
        .catch((error) => {
            debug('fetchRecords error: ' + error, 'Family Tree - Memorials');
        });
    }, [selectedMemorialId, props.selectedNode]);

    // useEffect(() => {
    //     setShow(props.handleOpen);
    // }, [props.handleOpen]);

    const processMemorialData = (responseJson: PageResultType) => {
        setMemorials(buildMemorialsJSX(responseJson));
    }

    const buildMemorialsJSX = (response: PageResultType): React.ReactElement[] => {
        const result: React.ReactElement[] = [];

        response.data.forEach((memorial: MemorialType) => {

            let pictureSrc: string = '/images/headshot-placeholder.svg';
            if (memorial.memorialPictureId) pictureSrc = `${ASSET_URL}/public/picture/${memorial.memorialPictureId}`;

            result.push(
                <div key={memorial.memorialId}
                    className={`family-tree-search-item ${selectedMemorialId === memorial.memorialId ? 'selected' : ''}`}
                    onClick={() => {handleSelectMemorial(memorial.memorialId)}}
                >
                    <div className="search-item-content">
                        <img src={pictureSrc} />

                        <div className="search-item-text">
                            <p>{memorial.firstName} {memorial.lastName}</p>
                            <p>{memorial.birthYear} - {memorial.deathYear}</p>
                        </div>
                    </div>

                    <div className={`search-item-radio ${selectedMemorialId === memorial.memorialId ? 'selected' : ''} my-auto`}>
                        <div className="radio-selected"></div>
                    </div>

                </div>

            );
        });

        return result;
    }
    
    const handleSelectMemorial = (id: string) => {
        setSelectedMemorialId((prevId) => (prevId === id ? null : id));
        
        setFormState({
            treeNodeId: props.selectedNode.treeNodeId,
            userId: '',
            memorialId: id,
            relationship: props.selectedNode.relationship,
            data: {
                posX: props.selectedNode.data.posX,
                posY: props.selectedNode.data.posY,
                handleTo: props.selectedNode.data.handleTo,
                handleFrom: props.selectedNode.data.handleFrom,
                fromTreeNodeId: props.selectedNode.data.fromTreeNodeId
            }
        });
    }

    const handleShow = () => {
        setShow(true);
    }

    const handleConfirmSelection = () => {
        console.log('formState: ', formState);
        console.log('props.selectedNode: ', props.selectedNode);
        postFormData(
            commonData.token,
            `user/loggedIn/familyTree`,
            [formState],
            (response: any) => {handleClose()},
            (error: any) => {}
        );
    }

    const handleClose = () => {
        props.closeModal();
    }

    return (
        <>

            {/* <div className="selected-tile-link" onClick={handleShow}>
                <p>Link an existing memorial</p>
                <img src="/images/fl-icon-connect-memorial.svg" />
            </div> */}

            <Modal show={props.showModal} fullscreen={true} onHide={handleClose}>
                <Modal.Header>
                    <p className="modal-title">Link a Memorial</p>
                    
                    <div className={`btn btn-modal-close`} onClick={handleClose} >
                        <img src="/images/modal-close-x.svg"/>
                    </div>
                </Modal.Header>

                <Modal.Body className="p-3">
                    <div className="input-group">
                        <input className="family-tree-search-input" type="text" placeholder="Search all memorials" />
                        <div className="search-icon-wrapper">
                            <img src="/images/fl-icon-search.svg" />
                        </div>
                    </div>

                    <p className="fl-txt-home-subheading mt-4 mb-3">Your Memorials</p>

                    <div className="d-flex flex-column gap-3">
                        {memorials}
                    </div>                    
                </Modal.Body>
                
                <Modal.Footer>
                    <button className="btn btn-modal-footer" onClick={handleConfirmSelection}>Continue</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}