import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "animate.css"
import { deleteRecord, postFormData } from "../../utils/AppUtil";
import { AppContext } from "../../AppContext";
import { MemorialContext } from "../../MemorialContext";
import { familyTreeUpdateType, MemorialType } from "../AppType";
import { ModalCreateMemorial } from "../Modals/ModalCreateMemorial";
import { LinkMemorial } from "./LinkMemorial";

type ModalProps = {
    detailedNodeData: familyTreeUpdateType;

    id: string;
    memorial?: MemorialType;
    firstName: string;
    lastName: string;
    relation: string;
    posX: number;
    posY: number,
    displayType: string;
    handleOpen: boolean;
    closeModal: () => void;
    openMemorial: () => void;
    handleExtentionClick: (
        firstName: string,
        posX: number,
        posY: number,
        treeNodeId: string
    ) => void;
    deleteNode: (id: string) => void;
}

interface HandleProps extends ModalProps {
    openModal: (modal: string) => void;
}

export const DetailedNode: React.FC<HandleProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Modal states
    const [show, setShow] = useState(false);

    const [modalSuccessDelete, setModalSuccessDelete] = useState<boolean>(false);
    const [modalSuccessAnimation, setModalSuccessAnimation] = useState<string>('animate__animated animate__fadeIn animate__faster');

    useEffect(() => {
        setShow(props.handleOpen);
    }, [props.handleOpen]);

    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        props.closeModal();
    }

    const handleAddRelative = () => {
        props.handleExtentionClick( props.firstName, props.posX, props.posY, props.id);
        handleClose();
    }

    const handleDelete = () => {
        props.deleteNode(props.id);
        setModalSuccessDelete(true);
        
        setTimeout(() => {
            handleClose()
        }, 2000);

        setTimeout(() => {
            setModalSuccessDelete(false);
            setModalSuccessAnimation('animate__animated animate__fadeIn animate__faster');          
        }, 3000);

    }

    const handleClick = () => {
        setMemorialData(props.memorial as MemorialType)
        props.openMemorial();
        handleClose();
    }

    const handleModalChange = (modal: string) => {
        props.openModal(modal);
        setTimeout(() => {
            handleClose();
        }, 500);
    }

    return (
        <>
            <Modal show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Body className="p-0">
                    <div className={`family-tree-tile-view `}>
                        <div className={`btn btn-modal-close`} onClick={handleClose} style={{zIndex: 9999}}>
                            <img src="/images/modal-close-x.svg"/>
                        </div>

                        <div className="selected-tile-wrapper">
                            <div className="background-text">{props.lastName}</div>

                            <div className={`family-tree-node ${props.displayType}-node selected-tile`}>
                                <div className="d-flex flex-column align-items-center gap-2">
                                    <img src="/images/headshot-placeholder.svg" />

                                    <div className="d-flex flex-column">
                                        <p className="node-name tile-name">{props.firstName}</p>
                                        <p className="node-name tile-name">{props.lastName}</p>
                                    </div>
                                </div>

                                <p className="node-relation tile-relation">{props.relation}</p>
                            </div>
                        </div>

                        {props.displayType === 'proxy-user' &&
                            <>
                                <div className="selected-tile-link">
                                    <p>Invite {props.firstName}</p>
                                    <img src="/images/fl-icon-send.svg" />
                                </div>
                            </>
                        }

                        {props.displayType === 'proxy-memorial' &&
                            <>
                                <div className="selected-tile-link" onClick={() => handleModalChange('createMemorial')}>
                                    <p>Create {props.firstName}'s memorial</p>
                                    <img src="/images/fl-icon-create-memorial.svg" />
                                </div>

                                <div className="selected-tile-link" onClick={() => handleModalChange('linkMemorial')}>
                                    <p>Link an existing memorial</p>
                                    <img src="/images/fl-icon-connect-memorial.svg" />
                                </div>
                            </>
                        }

                        {props.displayType === 'memorial' &&
                            <>
                                <div className="selected-tile-link" onClick={handleClick}>
                                    <p>View {props.firstName}'s memorial</p>
                                    <img src="/images/fl-icon-arrow-in-circle.svg" />
                                </div>
                            </>
                        }

                        <div className="selected-tile-link"
                            onClick={handleAddRelative}
                        >
                            <p>Add a relative to {props.firstName}</p>
                            <img src="/images/fl-plus-lg.svg" width={24} height={24} />
                        </div>

                        <div className="selected-tile-bottom-pills">
                            <div className="selected-tile-pill edit">
                                <img src="/images/pill-icon-pen.svg" />
                                <p>Edit relationships</p>
                            </div>

                            <div className="selected-tile-pill remove" onClick={() => handleDelete()}>
                                <img src="/images/pill-icon-trash.svg" />
                                <p>Remove from tree</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                {modalSuccessDelete && 
                    <div className={`modal-success-div ${modalSuccessAnimation}`} style={{zIndex: 999}}>
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center h-100">
                            <img src="/images/fl-login-tick.svg" />
                            <div className="login-success-txt d-flex flex-column gap-1">
                                <p>{props.firstName} removed from Family Tree</p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                }

            </Modal>
        </>
    );
}