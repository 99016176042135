interface Window {
    deferredPrompt: any;
}

export type Application = {
    auth: Auth,
    state: {
        memorialPictureId: string,
    }
}

// Auth
export type Auth = {
    token: string,
    userId: string,
    firstName: string,
    lastName: string,
    username: string,
    email: string,
    cellphone: string
}

export type PaymentProviderType = {
    paymentProviderId: string,
    name: string,
    sandbox: boolean
}[]

// Login
export type LoginType = {
    email: string,
    password: string
}

// Data
export type PageResultType = { 
    data: any[],
    pageNum: number,
    pageSize: number,
    numRecords: number,
    numPages: number
}

// Memorial Tabs - represented as buttoms at the bottom of the Memorial Journey.
export enum MemorialTab {
    home = 'pageHome',
    tab1 = 'pageLifeStory',
    tab2 = 'pageFamilyTree',
    tab3 = 'pageTreeOfLife',
    tab4 = 'pageTombstone'
}

// Forever Loved Days
export type ForeverLovedDaysType = {
    userId: string,
    userFirstName: string,
    userLastName: string,
    memorial: {
      memorialId: string,
      firstName: string,
      lastName: string,
      familyStory: string,
      birthDate: string,
      birthDateDisplay: string,
      birthYear: number,
      deathDate: string,
      deathDateDisplay: string,
      deathYear: number,
      afterSunset: true,
      yahrzeitDate: string,
      yahrzeitDisplay: string,
      memorialPictureId: string,
      coverPictureId: string,
      creator: {
        userId: string,
        firstname: string,
        lastname: string,
        email: string,
        mobileNumber: string,
        gender: string,
        dateOfBirth: string,
        profilePicture: {
          pictureId: string,
          isVideo: true
        },
        currency: {
          currencyId: string,
          symbol: string,
          name: string
        }
      },
      role: string,
      religiousBelief: {
        religiousBeliefId: string,
        name: string
      },
      locationDesc: string,
      locationLong: number,
      locationLat: number
    },
    relationship: string,
    date: string,
    dateDesc: string,
    dateType: string
}

// Memorial Get
export type MemorialType = {
    memorialId: string,
    firstName: string,
    lastName: string,
    familyStory: string,
    birthDate: string,
    birthDateDisplay: string,
    birthYear: number,
    deathDate: string,
    deathDateDisplay: string,
    deathYear: number,
    memorialPictureId: string,
    coverPictureId: string,
    creator: {
        userId: string,
        firstname: string,
        lastname: string,
        email: string
    },
    role: string,
    religiousBelief: {
        religiousBeliefId: string,
        name: string
    }
}

// Memorial Create
export type CreateMemorialType = {
    firstName: string,
    lastName: string,
    gender: string,

    birthDateDay: string,
    birthDateMonth: string,
    birthDateYear: string,

    deathDateDay: string,
    deathDateMonth: string,
    deathDateYear: string,

    birthDate: string,
    deathDate: string,
    obituary: string,
    religiousBeliefId: string,
    userMemorialRelationshipId: string

    locationDesc: string,
    locationLong: number,
    locationLat: number
}

// Memorial Update
export type UpdateMemorialType = {
    firstName: string,
    lastName: string,
    birthDate: string,
    deathDate: string,
    obituary: string,
    memorialPictureId: string,
    role: string,
    religiousBeliefId: null,
    userMemorialRelationshipId: null
}

// Family Story Update
export type UpdateFamilyStoryType = {
    familyStory: string,
}

// Memorial Picture Update
export type UpdateMemorialPictureType = {
    memorialPictureId: string,
    coverPictureId: string,
    religiousBeliefId: null
}

// Memorial Profile Update
export type UpdateMemorialPicture = {
    memorialPictureId: string,
    religiousBeliefId: null
}

// Memorial Cover Update
export type UpdateCoverPicture = {
    coverPictureId: string,
    religiousBeliefId: null
}

// Picture upload
export type PictureUploadType = {
    memorialId: string,
    file: any
}

// Picture upload for user
export type UserPictureUploadType = {
    memorialId: string,
    file: any
}

// Picture update
export type PictureUpdateType = {
    pictureId: string,
    file: any
}

// Picture Gallery
export type PictureGalleryType = {
    pictureId: string,
    seqNum: null
}

// User
export type UserType = {
    firstName: string,
    lastName: string,
    mobileNumber: string,
    email: string,
    password: string,
    dateOfBirth: string,
    birthDateDay: string,
    birthDateMonth: string,
    birthDateYear: string
}

export type GetUserType = {
    firstname: string,
    lastname: string,
    mobileNumber: string,
    gender: string,
    email: string,
    password: string,
    dateOfBirth: string
    profilePicture: {
        pictureId: string,
        isVideo: boolean
    }
}

// User update
export type UserUpdateType = {
    firstname: string,
    lastname: string,
    mobileNumber: string,
    email: string,
    password: string,
    dateOfBirth: string,
    profilePictureId: string
}

// Currency type
export type CurrencyType = {
    currencyId: string,
    symbol: string,
    name: string
}[]


// Leaf donation (get)
export type LeafDonationType = {
    leafId: string,
    amount: number,
    amountDisplay: string,
    user: {
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      cellphone: string
    },
    charity: {
      charityId: string,
      name: string
    },
    currencySymbol: string,
    leafType: {
        leafTypeId: string,
        name: string,
        color: string,
        numDays: number
    }
    invoice: {
        invoiceId: string,
        currency: {
            currencyId: string,
            symbol: string,
            name: string
        }
        amount: number,
        amountDisplay: string
    }
}

// Leaf donate (post)
export type LeafDonateType = {
    leafTypeId: string,
    paymentProviderId: string,
    currencyId: string | null | undefined,
    amount: number,
    anonymous: boolean
}

// Leaf type
export type LeafType = {
    leafTypeId: string,
    name: string,
    color: string,
    numDays: number,
    prices: [
        {
            currency: {
                currencyId: string,
                symbol: string,
                name: string
            },
            price: number
        }
    ]
}

// Gift donation (get)
export type GiftDonationType = {
    giftId: string,
    memorialId: string,
    anonymous: true,
    giftType: {
        giftTypeId: string,
        name: string,
        isFree: true,
        prices: [{
            currency: {
                currencyId: string,
                symbol: string,
                name: string
            },
            price: number
            }],
        seq: number
    },
    user: {
      userId: string,
      firstname: string,
      lastname: string,
      email: string,
      mobileNumber: string,
      gender: string,
      dateOfBirth: string,
      currency: {
        currencyId: string,
        symbol: string,
        name: string
      }
    },
    invoice: {
      invoiceId: string,
      currency: {
        currencyId: string,
        symbol: string,
        name: string
      },
      amount: number,
      amountDisplay: string,
      user: {
        userId: string,
        firstname: string,
        lastname: string,
        email: string,
        mobileNumber: string,
        gender: string,
        dateOfBirth: string,
        currency: {
          currencyId: string,
          symbol: string,
          name: string
        }
      }
    },
    message: string
  }

// Gift donate (post)
export type GiftDonateType = {
    giftTypeId: string,
    message: string,
    paymentProviderId: string,
    currencyId: string | null | undefined,
    anonymous: boolean
}

// Gift type
export type GiftType = {
    giftTypeId: string,
    name: string,
    isFree: boolean,
    prices: [{
        currency: {
            currencyId: string,
            symbol: string,
            name: string
        },
        price: number
    }],
    seq: number
}

// Message
export type MessageType =  {
    messageId: string,
    message: string,
    messageDate: string,
    messageDateDisplay: string,
    subscriber: {
        subscriberId: string,
        firstName: string,
        lastName: string,
        email: string
    }
}

// Memories
export type MemoryType = {
    memorialId: string,
    memorialFirstName: string,
    memorialLastName: string,
    memoryId: string,
    status: string,
    title: string,
    message: string,
    creator: {
        userId: string,
        firstname: string,
        lastname: string,
        email: string
    },
    pictures: {
        pictureId: string,
        isVideo: boolean
    }[],
    extra: {
        additionalProp1: {},
        additionalProp2: {},
        additionalProp3: {}
    },
    created: string
}

// Memory assets
export type MemoryAssets ={
    pictureId: string,
	isVideo: boolean
}[]

// Grouped structure for each memorialId
export type GroupedMemories = {
    memorialId: string;
    memories: MemoryType[];
}

// Life Event Create
export type MemoryCreateType = {
    title: string,
    message: string,
    pictureIds: string[],
    extra: {
        additionalProp1: {},
        additionalProp2: {},
        additionalProp3: {}
    }
  }

// Life EventType
export type LifeEventType = {
    decadeDisplay: string,
    lifeEvents: LifeEvent[]
}

// Life Event
export type LifeEvent = {
    memorialId: string,
    memorialFirstName: string,
    memorialLastName: string,
    lifeEventId: string,
    type: string,
    title: string,
    message: string,
    eventDate: string,
    eventDateDisplay: string,
    eventYear: number,
    pictureIds: string[],
    locations: Location[],
    creator: {
      userId: string,
      firstname: string,
      lastname: string,
      email: string,
      mobileNumber: string,
      gender: string,
      dateOfBirth: string,
      currency: {
        currencyId: string,
        symbol: string,
        name: string
      }
    },
    created: string
}

// Grouped structure for each memorialId
export type GroupedLifeEvents = {
    memorialId: string;
    lifeEvents: LifeEvent[];
}

export type storyType = {
    created: string
}

interface Location {
    latitude: number;
    longitude: number;
    description: string;
}

// Life Event Create
export type LifeEventCreateType = {
    title: string,
    message: string,
    eventDate: string,
    eventDateDay: string,
    eventDateMonth: string,
    eventDateYear: string,
    pictureIds: string[],
    locations: Location[]
}

// Memory Stories
export type StoryLifeEventArrayType = {
    memorialId: string,
    memorialFirstName: string,
    memorialLastName: string,
    lifeEventId: string,
    type: string,
    title: string,
    message: string,
    created: string,
    eventDate: string,
    eventDateDisplay: string,
    eventYear: number,
    status: string,
    creator: {
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    mobileNumber: string,
    gender: string,
    dateOfBirth: string,
    profilePicture: {
        pictureId: string,
        isVideo: boolean
    },
    currency: {
        currencyId: string,
        symbol: string,
        name: string
    }
    },
    pictureIds: [string],
    extra: {
        additionalProp1: {},
        additionalProp2: {},
        additionalProp3: {}
    },
    locations: [
    {
        description: string,
        longitude: number,
        latitude: number
    }
    ]
}[]

export type StoryLifeEventType = {
    memorialId: string,
    memorialFirstName: string,
    memorialLastName: string,
    lifeEventId: string,
    type: string,
    title: string,
    message: string,
    created: string,
    eventDate: string,
    eventDateDisplay: string,
    eventYear: number,
    status: string,
    creator: {
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    mobileNumber: string,
    gender: string,
    dateOfBirth: string,
    profilePicture: {
        pictureId: string,
        isVideo: boolean
    },
    currency: {
        currencyId: string,
        symbol: string,
        name: string
    }
    },
    pictureIds: [string],
    extra: {
        additionalProp1: {},
        additionalProp2: {},
        additionalProp3: {}
    },
    locations: [
    {
        description: string,
        longitude: number,
        latitude: number
    }
    ]
}

// Memory Stories
export type StoryMemoriesArrayType = {
    memorialId: string,
    memorialFirstName: string,
    memorialLastName: string,
    created: string,
    memoryId: string,
    status: string,
    title: string,
    message: string,
    creator: {
        userId: string,
        firstName: string,
        lastName: string,
        email: string,
        mobileNumber: string,
        gender: string,
        dateOfBirth: string,
        profilePicture: {
        pictureId: string,
        isVideo: boolean
    },
    currency: {
        currencyId: string,
        symbol: string,
        name: string
    }
    },
    pictures: [
        {
        pictureId: string,
        isVideo: boolean
        }
    ],
    extra: {
        additionalProp1: {},
        additionalProp2: {},
        additionalProp3: {}
    }
}[]

export type StoryMemoriesType = {
    memorialId: string,
    memorialFirstName: string,
    memorialLastName: string,
    created: string,
    memoryId: string,
    status: string,
    title: string,
    message: string,
    creator: {
        userId: string,
        firstName: string,
        lastName: string,
        email: string,
        mobileNumber: string,
        gender: string,
        dateOfBirth: string,
        profilePicture: {
        pictureId: string,
        isVideo: boolean
        },
        currency: {
        currencyId: string,
        symbol: string,
        name: string
        }
    },
    pictures: [
        {
        pictureId: string,
        isVideo: boolean
        }
    ],
    extra: {
        additionalProp1: {},
        additionalProp2: {},
        additionalProp3: {}
    }
}

// Stories
export type StoryType = {
    eventId: string,
    title: string,
    message: string,
    creator: {
        userId: string,
        firstname: string,
        lastname: string,
        email: string
    },
    pictures: {
        pictureId: string,
        isVideo: boolean
    }[],
}

// New Picture type including title and message
export type PictureWithDetails = {
    pictureId: string;
    isVideo: boolean;
    title: string;
    message: string;
  };

export type combinedStoriesType = {
    title: string,
    message: string,
    creator: {
        userId: string,
        firstname: string,
        lastname: string,
        email: string
    },
    pictures: {
        pictureId: string,
        isVideo: boolean
    }[],
}

export type MemorialUserType = {
    userId: string,
    role: string
  }

export type MemorialUserRoleType = {
    role: string,
    user: {
        userId: string,
        firstname: string,
        lastname: string,
        email: string,
        mobileNumber: string,
        gender: string,
        dateOfBirth: string,
        currency: {
            currencyId: string,
            symbol: string,
            name: string
        }
    }
}

export type addRelativeType = {
    firstName: string,
    lastName: string,
    passed: boolean
}

// Family tree
export type familyTreePostType = {
    userId: string,
    memorialId: string,
    proxyFirstName: string,
    proxyLastName: string,
    proxyType: string,
    relationship: string,
    data: {
        posX: number,
        posY: number,
        handleTo: string,
        handleFrom: string,
        fromTreeNodeId: string
    }
}

export type familyTreeUpdateType = {
    treeNodeId: string,
    userId: string,
    memorialId: string,
    relationship: string,
    proxyFirstName?: string,
    proxyLastName?: string,
    proxyType?: string,
    data: {
        posX: number,
        posY: number,
        handleTo: string,
        handleFrom: string,
        fromTreeNodeId: string
    }
}

export type familyTreeGetType = {
    treeNodeId: string,
    user: {
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      mobileNumber: string,
      gender: string,
      dateOfBirth: string,
      profilePicture: {
        pictureId: string,
        isVideo: true
      },
      currency: {
        currencyId: string,
        symbol: string,
        name: string
      }
    },
    memorial: {
      memorialId: string,
      firstName: string,
      lastName: string,
      familyStory: string,
      birthDate: string,
      birthDateDisplay: string,
      birthYear: number,
      deathDate: string,
      deathDateDisplay: string,
      deathYear: number,
      afterSunset: true,
      yahrzeitDate: string,
      yahrzeitDisplay: string,
      memorialPictureId: string,
      coverPictureId: string,
      creator: {
        userId: string,
        firstame: string,
        lastame: string,
        email: string,
        mobileNumber: string,
        gender: string,
        dateOfBirth: string,
        profilePicture: {
          pictureId: string,
          isVideo: true
        },
        currency: {
          currencyId: string,
          symbol: string,
          name: string
        }
      },
      role: string,
      religiousBelief: {
        religiousBeliefId: string,
        name: string
      },
      locationDesc: string,
      locationLong: number,
      locationLat: number
    },
    proxyFirstName: string,
    proxyLastName: string,
    proxyType: string,
    relationship: string,
    data: {
      posX: number,
      posY: number,
      fromTreeNodeId: string,
      handleFrom: string,
      handleTo: string
    }
}

export type familyTreeNode = {
    id: string,
    position: {
        x: number,
        y: number
    },
    type?: string,
    data: {
        displayType: string,
        firstName: string,
        lastName: string,
        relation:string,

        targetHandle: any,
        sourceHandleTop: any,
        sourceHandleRight: any,
        sourceHandleBottom: any,
        sourceHandleLeft: any,

        targetHandleId: string,
        sourceHandleTopId: string,
        sourceHandleRightId: string,
        sourceHandleBottomId: string,
        sourceHandleLeftId: string,

        extensionPos: string
    }
}

export type DetailedNode = {
    id: string,
    firstName: string,
    lastName: string,
    relation: string,
    posX: number,
    posY: number,
    displayType: string
}