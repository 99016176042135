import React, { useCallback, useEffect, useState } from "react";
import { Handle, Position, useEdges } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import "animate.css"
import { ASSET_URL } from "../../utils/AppUtil";

type NodeProps = {
    data: {
        id: string,
        position: { x: number, y: number },
        fromTreeNodeId: string,
        handleFrom: string,

        userId: string,
        memorial: string,

        displayType: string,
        firstName: string,
        lastName: string,
        relation: string,
        pictureId: string,
        gender: string,

        targetHandle: Position,
        sourceHandleTop: Position,
        sourceHandleRight: Position,
        sourceHandleBottom: Position,
        sourceHandleLeft: Position,

        targetHandleId: string,
        sourceHandleTopId: string,
        sourceHandleRightId: string,
        sourceHandleBottomId: string,
        sourceHandleLeftId: string,

        extensionPos: string,
        handleExtentionClick: (
            firstName: string,
            posX: number,
            posY: number,
            treeNodeId: string
        ) => void,
        handleNodeClick: (
            treeNodeId: string,
            userId: string,
            memorialId: string,
            proxyFirstName: string,
            proxyLastName: string,
            proxyType: string,
            relationship: string,
            posX: number,
            posY: number,
            handleTo: string,
            handleFrom: string,
            fromTreeNodeId: string,
            displayType: string,
        ) => void
    }
}

export const TreeNode: React.FC<NodeProps> = ({ data }) => {

    const [showExtension, setShowExtension] = useState<boolean>(true);
    const edges = useEdges(); // Get all edges in the graph

    const checkHandleConnected = (handleId: string) => {
        return edges.some(edge => 
            edge.sourceHandle === handleId || edge.targetHandle === handleId
        );
    };

    const handleIds = [
        data.sourceHandleTopId,
        data.sourceHandleRightId,
        data.sourceHandleBottomId,
        data.sourceHandleLeftId
    ];

    useEffect(() => {

        // Check if any handle is connected
        const anyConnected = handleIds.some((handleId: string) => checkHandleConnected(handleId));
        
        if (anyConnected) {
            setShowExtension(false);
        }
    }, [handleIds, checkHandleConnected]);

    const handleStyle = (handleId: string) => {
        return {
            background: 'white',
            display: checkHandleConnected(handleId) ? 'block' : 'none'
        };
    };

    const onChange = useCallback((evt: any) => {
        console.log(evt.target.value);
    }, []);

    let pictureSrc: string = '/images/headshot-placeholder.svg';
    if (data.pictureId) pictureSrc = `${ASSET_URL}/public/picture/${data.pictureId}`;
    else if ((data.relation === 'Father' || data.relation === 'Brother' || data.relation === 'Son') || data.gender === 'MALE') pictureSrc = '/images/headshot-placeholder.svg';
    else if ((data.relation === 'Mother' || data.relation === 'Sister' || data.relation === 'Daughter') || data.gender === 'FEMALE') pictureSrc = '/images/headshot-placeholder-female.svg';

      return (
        <>
            <Handle
                id={data.targetHandleId}
                type="target"
                position={data.targetHandle}
                style={{background: 'white'}} />

            <Handle
                id={data.sourceHandleTopId}
                type="source"
                position={data.sourceHandleTop}
                style={handleStyle(data.sourceHandleTopId)} />

            <Handle
                id={data.sourceHandleRightId}
                type="source"
                position={data.sourceHandleRight}
                style={handleStyle(data.sourceHandleRightId)} />

            <Handle
                id={data.sourceHandleBottomId}
                type="source"
                position={data.sourceHandleBottom}
                style={handleStyle(data.sourceHandleBottomId)} />

            <Handle
                id={data.sourceHandleLeftId}
                type="source"
                position={data.sourceHandleLeft}
                style={handleStyle(data.sourceHandleLeftId)} />

            {/* {showExtension && */}
                <img
                    className={`family-tree-extension ${data.extensionPos}`}
                    src="images/btn-add-node-filled.svg"
                    width={20}
                    height={20}
                    onClick={() => data.handleExtentionClick(
                        data.firstName,
                        data.position.x,
                        data.position.y,
                        data.id
                    )}
                />
            {/* } */}

            <div className={`family-tree-node ${data.displayType}-node`}
                onClick={() => data.handleNodeClick(
                    data.id,
                    data.userId,
                    data.memorial,
                    data.firstName,
                    data.lastName,
                    data.displayType,
                    data.relation,
                    data.position.x,
                    data.position.y,
                    data.targetHandle,
                    data.handleFrom,
                    data.fromTreeNodeId,
                    data.displayType,
                )}
            >
                <div className="d-flex flex-column align-items-center gap-2">
                    <img src={pictureSrc} />

                    <div className="d-flex flex-column">
                        <p className="node-name">{data.firstName}</p>
                        <p className="node-name">{data.lastName}</p>
                    </div>
                </div>

                <p className="node-relation">{data.relation}</p>
            </div>
        </>
      );
}