import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const PreventBackNav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleBackButton = () => {
      navigate(location.pathname); // Redirect back to the current page
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate, location]);

  return null;
};
